p, ul, a, li {
    font-size: 1.06rem;
    letter-spacing: 0.2px;
}

    a:hover {
        text-decoration: underline !important;
    }
/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}


.btn-primary {
    background-color: #3774b0;
    border-color: #3774b0;
}

.btn-primary:hover {
    background-color: #2c669e;
}

.text-primary {
    /*  color: #3774b0 !important;*/
}

.no-bullets {
    list-style-type: none;
}

.pointer {
    cursor: pointer;
}

#chart-container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: 400px;
    background-color: #fff;
}


.logo-img {
/*    width: 120px;
    vertical-align: inherit;*/
}

.iot-icon {
/*    width: 100px;
    margin: 0 auto;
    padding: 16px;*/
}

.iot-solution-img {
/*    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;*/
}

.bg-arshon {
    background-color: #3774b0
}

.bg-second {
    background-color: #314673
}

.main-wrapper {
    padding: 100px 0;
    margin: 0 !important;
    background: #ececec;
    /* 
    background: #edf3f9; /* Old browsers */
    /* 
    background: -moz-linear-gradient( right, #08142e, #314673, #3774b0 ); /* FF3.6-15 */
    /* 
    background: -webkit-linear-gradient( right, #08142e, #314673, #3774b0 ); /*Chrome10-25,Safari5.1-6 */
    /* 
    background: linear-gradient( to left, #08142e, #314673, #3774b0 ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.main-img {
    width: 100%;
    height: auto;
}

.blog-bg {
    background-color: #3774b0;
    height: 500px;
    width: 100%;
    position: absolute;
    top: 58px;
    z-index: -999
}

.cover {
    object-fit: cover
}

@media screen and (min-width:576px) and (max-width:992px) {
    .main-img {
        width: 80%;
    }
}
/* line 8, src/assets/style/components/_toggle-switch.scss */
.toggle-switch {
    position: relative;
    margin-right: 10px;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
/* line 19, src/assets/style/components/_toggle-switch.scss */
.toggle-switch-checkbox {
    display: none;
}
/* line 23, src/assets/style/components/_toggle-switch.scss */
.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
}
    /* line 31, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch-label:focus {
        outline: none;
    }
        /* line 34, src/assets/style/components/_toggle-switch.scss */
        .toggle-switch-label:focus > span {
            box-shadow: 0 0 2px 5px red;
        }
    /* line 39, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch-label > span:focus {
        outline: none;
    }
/* line 44, src/assets/style/components/_toggle-switch.scss */
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
    /* line 50, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch-inner:before, .toggle-switch-inner:after {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
    }
    /* line 64, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch-inner:before {
        content: attr(data-yes);
        text-transform: uppercase;
        padding-left: 10px;
        background-color: #2F855A;
        color: #fff;
    }
/* line 73, src/assets/style/components/_toggle-switch.scss */
.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
    /* line 77, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch-disabled:before {
        background-color: #ddd;
        cursor: not-allowed;
    }
/* line 83, src/assets/style/components/_toggle-switch.scss */
.toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
}
/* line 92, src/assets/style/components/_toggle-switch.scss */
.toggle-switch-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
/* line 107, src/assets/style/components/_toggle-switch.scss */
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
/* line 111, src/assets/style/components/_toggle-switch.scss */
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
}
/* line 116, src/assets/style/components/_toggle-switch.scss */
.toggle-switch.small-switch {
    width: 40px;
}
    /* line 120, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
        content: "";
        height: 20px;
        line-height: 20px;
    }
    /* line 128, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch.small-switch .toggle-switch-switch {
        width: 16px;
        right: 20px;
        margin: 2px;
    }

@media screen and (max-width: 991px) {
    /* line 8, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch {
        transform: scale(0.9);
    }
}

@media screen and (max-width: 767px) {
    /* line 8, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch {
        transform: scale(0.825);
    }
}

@media screen and (max-width: 575px) {
    /* line 8, src/assets/style/components/_toggle-switch.scss */
    .toggle-switch {
        transform: scale(0.75);
    }
}
